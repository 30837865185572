import styled from 'styled-components'

export const SectionPageNotFound = styled.section`
  margin-top: 80px;
  padding: 80px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .texto {
    margin-right: 111px;
    h2 {
      font: normal bold 72px/68px 'DepotNew';
      color: #0062a6;
      margin-bottom: 32px;
    }
    a {
      display: flex;
      align-items: center;
      color: #0062a6;
      img {
        margin-right: 10px;
      }
    }
  }
  @media (max-width: 1050px) {
    padding: 40px 0px;
    margin-top: 60px;
    .container {
      flex-direction: column-reverse;
      align-items: center;
    }
    .texto {
      margin-right: 0px;
      h2 {
        font-size: 48px;
        text-align: center;
        line-height: 47px;
        margin-bottom: 20px;
      }
      a {
        justify-content: center;
      }
    }
    .ilustra {
      width: 87%;
      margin-bottom: 40px;
    }
  }
`
