import React, { Component } from 'react'
import AOS from 'aos'
import '../Style/aos.css'

import { Link } from 'gatsby'

import Header from 'components/header'
import { SectionPageNotFound } from '../Style/404'
import Footer from 'components/footer/main'
import Demo from 'components/s-demo/main'
import SEO from 'components/seo'

import img404 from 'images/img-404.svg'
import arrowback from 'images/arrow-blue-back.svg'

import 'sass/main.scss'

class Site extends Component {
  componentDidMount() {
    AOS.init({
      duration: 1000,
      once: true,
      disable: 'mobile',
    })
  }
  render() {
    return (
      <div className="main">
        <SEO title="Página não encontrada" />
        <Header />

        <SectionPageNotFound>
          <div className="container">
            <div className="texto">
              <h2>
                Página não <br />
                encontrada
              </h2>
              <Link to="/">
                <img src={arrowback} alt="Icone voltar" /> Voltar para o site
              </Link>
            </div>
            <img src={img404} alt="Icone página não encontrada" className="ilustra" />
          </div>
        </SectionPageNotFound>

        <Demo />

        <Footer padding_maior={true} />
      </div>
    )
  }
}

export default Site
